import { CompanyConnection } from './company';

export enum AccountsErrorCode {
  ACCOUNT_NUMBER_EXISTS = 0,
  ACCOUNT_NUMBER_OUT_OF_RANGE = 1,
  MISSING_COMPANY_ID_HEADER = 2,
  CORRUPTED_PAYLOAD = 3,
  UNKNOWN = 4,
};

export interface AccountLike {
  accountNr: number;
  name: string;
};

export type Account = AccountLike & {
  id: number;
  isVisible: boolean;
};

export type AccountToCreate = AccountLike & {
  isVisible: boolean;
};

export function trackByAccountNumber(index: number, account: Account | AccountLike): number {
  return account.accountNr;
}

export function labelByAccount(account: AccountLike): string {
  // DO NOT REMOVE ?? 'Unknown', there are some connections without 'name' property in account settings
  return `${account.accountNr} - ${account.name ?? 'Unknown'}`;
}

export type AccountSetting = Pick<Account, 'id'> & AccountLike;

export function isAccountLike(account: Account | AccountLike): account is AccountLike {
  return !('id' in account);
}

export function isAccount(account: Account | AccountLike): account is Account {
  return 'id' in account;
}

export type AvailableAccountsResponseError = {
  errorCode: AccountsErrorCode;
  errorMessage: string;
};

export type CreatedAccountsResponseError = {
  errorCode: AccountsErrorCode;
  errorMessage: string;
};

export function sortByAccountNrPredicate(accountA: AccountLike, accountB: AccountLike): number {
  return accountA.accountNr - accountB.accountNr;
}

export type AccountSeries = [1500, 1600] | [7770, 7800] | [3000, 3030] | [3030, 3050] | [3050, 3100] | [3100, 3110] | [3109, 3159] | [3000, 3159];

export enum AccountType {
  INTERIM = 'Interim',
  STANDARD = 'Standard',
  FEE = 'Fee',
  INCOME = 'Income',
}

export type AccountTypesWithoutIncome = Exclude<AccountType, AccountType.INCOME>;

export function isAccountOfSpecificSeriesPredicate(accountSeries: AccountSeries): (account: AccountLike) => boolean {
  return (account: AccountLike): boolean => account.accountNr >= accountSeries[0] && account.accountNr < accountSeries[1];
};

export function getAccountSeriesByAccountType(accountType: AccountTypesWithoutIncome): AccountSeries;
export function getAccountSeriesByAccountType(accountType: AccountType.INCOME, vatCodeType: VatCodeType): AccountSeries;
export function getAccountSeriesByAccountType(accountType: AccountType, vatCodeType?: VatCodeType): AccountSeries {
  switch (accountType) {
    case AccountType.INTERIM:
      return [1500, 1600];
    case AccountType.STANDARD:
      return [1500, 1600];
    case AccountType.FEE:
      return [7770, 7800];
    case AccountType.INCOME:
      if (!vatCodeType) {
        throw new Error('VAT code is required for Income account');
      }

      switch (vatCodeType) {
        case VatCodeType.TYPE_1:
          return [3000, 3030];
        case VatCodeType.TYPE_2:
          return [3030, 3050];
        case VatCodeType.TYPE_3:
          return [3050, 3100];
        case VatCodeType.TYPE_4:
        case VatCodeType.TYPE_5:
        case VatCodeType.TYPE_6:
          return [3100, 3110];
        case VatCodeType.TYPE_7:
          return [3109, 3159];
        default:
          return [3000, 3159];
      };
  };
}

export function isAccountOfSpecificTypePredicate(accountType: AccountTypesWithoutIncome): (account: AccountLike) => boolean;
export function isAccountOfSpecificTypePredicate(accountType: AccountType.INCOME, vatCodeType: VatCodeType): (account: AccountLike) => boolean;
export function isAccountOfSpecificTypePredicate(accountType: AccountType, vatCodeType?: VatCodeType): (account: AccountLike) => boolean {
  switch (accountType) {
    case AccountType.STANDARD:
    case AccountType.FEE:
    case AccountType.INTERIM:
      return isAccountOfSpecificSeriesPredicate(getAccountSeriesByAccountType(accountType));
    case AccountType.INCOME:
      if (!vatCodeType) {
        throw new Error('VAT code is required for Income account');
      }

      return isAccountOfSpecificSeriesPredicate(getAccountSeriesByAccountType(accountType, vatCodeType));
  }
};

export function generateAccountName(accountType: AccountTypesWithoutIncome, connection: CompanyConnection): string;
export function generateAccountName(accountType: AccountType.INCOME, connection: CompanyConnection, vatCodeType: VatCodeType): string;
export function generateAccountName(accountType: AccountType, connection: CompanyConnection, vatCodeType?: VatCodeType): string {
  switch (accountType) {
    case AccountType.STANDARD:
      return `${connection.externalSystemTypeId} oppgjør`;
    case AccountType.FEE:
      return `${connection.externalSystemTypeId} gebyr`;
    case AccountType.INTERIM:
      return `${connection.externalSystemTypeId} ukjente transaksjoner`;
    case AccountType.INCOME:
      if (!vatCodeType) {
        throw new Error('VAT code is required for Income account');
      }

      switch (vatCodeType) {
        case VatCodeType.TYPE_1:
          return `${connection.externalSystemTypeId} salg høy sats`;
        case VatCodeType.TYPE_2:
          return `${connection.externalSystemTypeId} salg middels sats`;
        case VatCodeType.TYPE_3:
          return `${connection.externalSystemTypeId} salg lav sats`;
        case VatCodeType.TYPE_4:
        case VatCodeType.TYPE_5:
        case VatCodeType.TYPE_6:
          return `${connection.externalSystemTypeId} salg avgiftsfritt sats`;
        case VatCodeType.TYPE_7:
          return `${connection.externalSystemTypeId} opptjent, ikke fakturert inntekt`;
        default:
          return `${connection.externalSystemTypeId} salg`;
      };
  }
}

export function getNearestUnoccupiedAccountNumberOrFirstAvailable(accountType: AccountTypesWithoutIncome, reservedAcountNumbers: number[]): number;
export function getNearestUnoccupiedAccountNumberOrFirstAvailable(accountType: AccountType.INCOME, reservedAcountNumbers: number[], vatCodeType: VatCodeType): number;
export function getNearestUnoccupiedAccountNumberOrFirstAvailable(accountType: AccountType, reservedAccountNumbers: number[], vatCodeType?: VatCodeType): number {
  let accountSeries: AccountSeries;

  switch (accountType) {
    case AccountType.STANDARD:
    case AccountType.FEE:
    case AccountType.INTERIM:
      accountSeries = getAccountSeriesByAccountType(accountType);

      break;
    case AccountType.INCOME:
      if (!vatCodeType) {
        throw new Error('VAT code is required for Income account');
      }

      accountSeries = getAccountSeriesByAccountType(accountType, vatCodeType);

      break;
  }

  for (let accountNumber = accountSeries[0] + 1; accountNumber < accountSeries[1]; ++accountNumber) {
    if (!reservedAccountNumbers.includes(accountNumber)) {
      return accountNumber;
    }
  }

  return accountSeries[0] + 1;
}

export function getNearestUnoccupiedAccountNumber(accountType: AccountTypesWithoutIncome, reservedAcountNumbers: number[]): number | undefined;
export function getNearestUnoccupiedAccountNumber(accountType: AccountType.INCOME, reservedAcountNumbers: number[], vatCodeType: VatCodeType): number | undefined;
export function getNearestUnoccupiedAccountNumber(accountType: AccountType, reservedAccountNumbers: number[], vatCodeType?: VatCodeType): number | undefined {
  let accountSeries: AccountSeries;

  switch (accountType) {
    case AccountType.STANDARD:
    case AccountType.FEE:
    case AccountType.INTERIM:
      accountSeries = getAccountSeriesByAccountType(accountType);

      break;
    case AccountType.INCOME:
      if (!vatCodeType) {
        throw new Error('VAT code is required for Income account');
      }

      accountSeries = getAccountSeriesByAccountType(accountType, vatCodeType);

      break;
  }

  for (let accountNumber = accountSeries[0] + 1; accountNumber < accountSeries[1]; ++accountNumber) {
    if (!reservedAccountNumbers.includes(accountNumber)) {
      return accountNumber;
    }
  }

  return undefined;
}

export enum BookkeepingDimensionsErrorCode {
  UNKNOWN = 0,
  MISSING_COMPANY_ID_HEADER = 1,
};

export type BookkeepingDimension = {
  id: number;
  name: string;
};

export type AvailableBookkeepingDimensionsResponseError = {
  errorCode: BookkeepingDimensionsErrorCode;
  errorMessage: string;
};

export type Department = BookkeepingDimension & {
  // Its actually is not optional, but old connections can have no department number in their settings
  departmentNumber?: string;
};

export type Project = BookkeepingDimension & {
  projectNumber: string;
}

export function trackByDepartmentId(index: number, department: Department): number {
  return department.id;
}

export function labelByDepartment(department: Department): string {
  return `${department.departmentNumber ?? department.id} - ${department.name}`;
}

export function trackByProjectId(index: number, project: Project): number {
  return project.id;
}

export function labelByProject(project: Project): string {
  return `${project.projectNumber} - ${project.name}`;
}

export enum VatCodeType {
  TYPE_1 = "3",
  TYPE_2 = "31",
  TYPE_3 = "33",
  TYPE_4 = "5",
  TYPE_5 = "52",
  TYPE_6 = "6",
  TYPE_7 = "7",
}

export const VAT_CODE_TYPES: VatCodeType[] = [
  VatCodeType.TYPE_1,
  VatCodeType.TYPE_2,
  VatCodeType.TYPE_3,
  VatCodeType.TYPE_4,
  VatCodeType.TYPE_5,
  VatCodeType.TYPE_6,
  VatCodeType.TYPE_7,
];

export interface VatCode {
  id: number;
  code: VatCodeType;
  name: string;
  vatPercent: number | null;
  groupingValue: number;
}

export function trackByVatCodeId(index: number, vatCode: VatCode): number {
  return vatCode.id;
}

export function labelByVatCode(vatCode: VatCode): string {
  return `${vatCode.code}: ${vatCode.vatPercent ?? '?'}% - ${vatCode.name}`;
}

export enum UnimicroPlatformDataErrorCode {
  UNKNOWN = 0,
  MISSING_COMPANY_ID_HEADER = 1,
  CORRUPTED_PAYLOAD = 2,
  ACCOUNT_NUMBER_OUT_OF_RANGE = 3,
  ACCOUNT_NUMBER_EXISTS = 4,
  INVALID_GROUP_NUMBER = 5,
}

export type UnimicroPlatformData = {
  accounts: Account[];
  departments: Department[];
  projects: Project[];
  outputVatCodes: VatCode[];
}

export type UnimicroPlatformDataOkResponse = UnimicroPlatformData;

export type UnimicroPlatformDataErrorResponse = {
  errorMessage: string;
  errorCode: UnimicroPlatformDataErrorCode;
}

export type UnimicroPlatformDataResponse = UnimicroPlatformDataOkResponse | UnimicroPlatformDataErrorResponse;

export type UnimicroPlatformInvoice = {
  systemType: string;
  systemId: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceDate: string;
  dueDate: string;
  totalAmount: number;
  currencyCode: string;
  invoiceType: number;
  customerName: string;
}
