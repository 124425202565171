import { Injectable } from '@angular/core';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@WebUi/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {

  @Select(DashboardState.isUserOwnerOfActiveCompany) readonly isUserOwnerOfActiveCompany$!: Observable<boolean>;

  readonly canDeleteCompany$: Observable<boolean>;

  readonly canDeleteCompanyUser$: Observable<boolean>;

  readonly canUpdateCompanyInvoiceSettings$: Observable<boolean>;

  constructor(private authService: AuthService) {
    this.canDeleteCompany$ = this.isUserOwnerOfActiveCompany$
      .pipe(
        map((isUserOwnerOfActiveCompany: boolean) => this.canUseSuModule() || isUserOwnerOfActiveCompany),
      );

    this.canDeleteCompanyUser$ = this.isUserOwnerOfActiveCompany$
      .pipe(
        map((isUserOwnerOfActiveCompany: boolean) => this.canUseSuModule() || isUserOwnerOfActiveCompany),
      );

    this.canUpdateCompanyInvoiceSettings$ = of(true);
  }

  canUseSuModule(): boolean {
    return this.authService.isAuthenticatedAsSuperUser();
  }

  canUseOrderDistribution(): boolean {
    return this.canUseSuModule();
  }

}
