import { ID } from './common';
import { ExternalSystem } from './external-system';
// import { PaymentVendorSettings } from './connection-settings/payment-provider-settings';
// import { ProductSyncSettings } from './connection-settings/product-sync-settings';
// import { SalesChannelSettings } from './connection-settings/sales-channel-settings';
// import { GoogleProductFeedSettings } from './connection-settings/feed-settings';
// import { SoftRigInvoiceSettings } from './connection-settings/softrig-invoice-settings';
// import { ConnectionSettings } from './connection-settings/connection-settings';

export interface PublicCompany {
  id: ID;
  companyNumber: string;
  name: string;
}

export function trackByCompanyId(index: number, company: PublicCompany): ID {
  return company.id;
}

export interface CompanyInfo extends PublicCompany {
  contactPerson: ContactPerson;
}

export interface Company extends CompanyInfo {
  invoiceRecipientEmail: string;
  billingAddress: BillingAddress;
}

export type CompanyToCreate = Omit<Company, 'id'>;

export interface BillingAddress {
  address1: string;
  countryCode: string;
  postalArea: string;
  postalCode: string;
}

export interface ContactPerson {
  email: string;
  name: string;
  phone: string;
}

export interface CompanyUser {
  companyId: ID;
  userId: ID;
  email: string;
  fullName: string;
  role: CompanyUserRole;
}

export function trackByCompanyUserId(index: number, companyUser: CompanyUser): ID {
  return companyUser.userId;
}

export enum CompanyUserRole {
  OWNER = 'OWNER',
  REGULAR = 'REGULAR',
}

export enum CompanyConnectionState {
  COMPLETE = 'Complete',
  PENDING_COMPLETE = 'PendingComplete',
  BROKEN = 'Broken',
  PENDING_DELETE = 'PendingDelete',
}

export interface CompanyConnection {
  id: ID;
  externalSystemId: string;
  externalSystemTypeId: ExternalSystem;
  state: CompanyConnectionState;
  stateMessage?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connectionData: any;
}

export function trackByCompanyConnectionId(index: number, companyConnection: CompanyConnection): ID {
  return companyConnection.id;
}

// export type CompanyConnection = {
//   id: ID;
//   externalSystemId: string;
//   state: CompanyConnectionState;
//   stateMessage: string | null;
// } & (
//     {
//       externalSystemTypeId: AccountingSystem;
//       connectionData: CompanyConnectionData & InvoiceConnectionData & ProductSyncConnectionData;
//     } | {
//       externalSystemTypeId: Feed.GOOGLE_PRODUCT_FEED;
//       connectionData: CompanyConnectionData & GoogleProductFeedConnectionData;
//     } | {
//       externalSystemTypeId: SalesChannel.GENERIC;
//       connectionData: CompanyConnectionData & SalesChannelConnectionData;
//     } | {
//       externalSystemTypeId: SalesChannel.MOOCOMMERCE;
//       connectionData: CompanyConnectionData & SalesChannelConnectionData & ProductSyncConnectionData;
//     } | {
//       externalSystemTypeId: SalesChannel.SHOPIFY;
//       connectionData: CompanyConnectionData & SalesChannelConnectionData;
//     } | {
//       externalSystemTypeId: SalesChannel.UNINETTBUTIKK;
//       connectionData: CompanyConnectionData & SalesChannelConnectionData & ProductSyncConnectionData;
//     } | {
//       externalSystemTypeId: InventoryManagementSystem;
//       connectionData: CompanyConnectionData;
//     } | {
//       externalSystemTypeId: PointOfSale;
//       connectionData: CompanyConnectionData;
//     } | {
//       externalSystemTypeId: PaymentProvider.CLEARHAUS;
//       connectionData: CompanyConnectionData & { paymentVendorSettings: ClearhausSettings; };
//     } | {
//       externalSystemTypeId: PaymentProvider.KLARNA;
//       connectionData: CompanyConnectionData & { paymentVendorSettings: KlarnaSettings; };
//     } | {
//       externalSystemTypeId: PaymentProvider.NETS;
//       connectionData: CompanyConnectionData & { paymentVendorSettings: NetsSettings; };
//     } | {
//       externalSystemTypeId: PaymentProvider.PAYPAL;
//       connectionData: CompanyConnectionData & { paymentVendorSettings: PaypalSettings; };
//     } | {
//       externalSystemTypeId: PaymentProvider.STRIPE;
//       connectionData: CompanyConnectionData & { paymentVendorSettings: StripeSettings; };
//     } | {
//       externalSystemTypeId: PaymentProvider.SVEA;
//       connectionData: CompanyConnectionData & { paymentVendorSettings: SveaSettings; };
//     } | {
//       externalSystemTypeId: PaymentProvider.VIPPS;
//       connectionData: CompanyConnectionData & { paymentVendorSettings: VippsSettings; };
//     }
//   );

// export interface PaymentProviderConnectionData {
//   paymentVendorSettings: PaymentVendorSettings;
// }

// export interface SalesChannelConnectionData {
//   salesChannelSettings: SalesChannelSettings;
// }

// export interface ProductSyncConnectionData {
//   productSyncSettings: ProductSyncSettings;
// }

// export interface InvoiceConnectionData {
//   invoiceSettings: SoftRigInvoiceSettings;
// }

// export interface GoogleProductFeedConnectionData {
//   googleProductFeedSettings: GoogleProductFeedSettings;
// }

// export interface CompanyConnectionData {
//   connectionSettings: ConnectionSettings;
// }

export type CompanyConnectionBySignalR = Omit<CompanyConnection, 'id'> & { id: number; };

