import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@WebUi/env';
import {
  ID,
  CompanyConnection,
  ExternalSystemKind,
  OnboardingContactForm,
  ExternalSystemTypeKind,
} from '@Libs/model';
import { Observable, map } from 'rxjs';
import { XEBRIDGE_COMPANY_ID } from '@WebUi/app/interceptors/x-ebridge-companyid.interceptor';

@Injectable({
  providedIn: 'root',
})
export class CompanyManagerApiService {

  constructor(private http: HttpClient) { }

  fetchCompanyAccountingSystem(companyId: ID): Observable<CompanyConnection | null> {
    return this.http.get<CompanyConnection[]>(`${environment.companyManagerService.private}/connection`, {
      context: new HttpContext().set(XEBRIDGE_COMPANY_ID, companyId),
      params: {
        'kind': ExternalSystemKind.ACCOUNTING_SYSTEM,
      },
    })
      .pipe(
        map((connections: CompanyConnection[]) => {
          if (connections.length === 0) {
            return null;
          }

          return connections[0];
        }),
      );
  }

  postContactFormOnboarding(companyId: ID, onboardingContactForm: OnboardingContactForm): Observable<void> {
    return this.http.post<void>(
      `${environment.companyManagerService.private}/contact-form/onboarding`,
      onboardingContactForm,
      {
        context: new HttpContext().set(XEBRIDGE_COMPANY_ID, companyId),
      },
    );
  }

  fetchExternalSystemTypeKind(): Observable<ExternalSystemTypeKind[] | null> {
    return this.http.get<ExternalSystemTypeKind[] | null>(`${environment.companyManagerService.anonymous}/external-system-type-kind`, {
      context: new HttpContext().set(XEBRIDGE_COMPANY_ID, false),
    });
  }

}
