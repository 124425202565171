import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import { isApolloError } from '@apollo/client/errors';
import { environment } from '@WebUi/env';
import Rollbar from 'rollbar';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: 'bba781c608d24fdba3cf9c192e35d29e',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.name,
  },
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(error: Error): void {
    if (!environment.logging.rollbar) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    const payload: any = {};

    if (isApolloError(error)) {
      payload.debugData = {
        graphQLErrors: JSON.stringify(error.graphQLErrors, undefined, 2),
        clientErrors: JSON.stringify(error.clientErrors, undefined, 2),
        networkError:
          error.networkError instanceof HttpErrorResponse
            ? JSON.stringify({
              ...error.networkError,
              headers: error.networkError.headers ? this._httpHeadersToObject(error.networkError.headers as HttpHeaders) : {},
            }, undefined, 2)
            : JSON.stringify(error.networkError, undefined, 2),
        extraInfo: JSON.stringify(error.extraInfo, undefined, 2),
      };
    }

    this.rollbar.error(error, payload);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  private _httpHeadersToObject(headers: HttpHeaders): any {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    const headersObject: any = {};

    for (const header of headers.keys()) {
      headersObject[header] = headers.get(header);
    }

    return headersObject;
  }

}

export function rollbarFactory(): Rollbar {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
