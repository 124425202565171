import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, timer } from 'rxjs';
import { Observable } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RetryInterceptor implements HttpInterceptor {

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        retryWhen(retryStrategyFactory(
          [
            1000,
            3000,
          ],
          (error: Error) => error instanceof HttpErrorResponse && error.status >= 500 && error.status <= 599,
        )),
      );
  }

}

export const retryStrategyFactory = (
  delays: number[],
  match?: (error: Error) => boolean,
  attemptNotifier?: (error: Error, attempt: number, retryIn: number) => void,
): (attempts: Observable<Error>) => Observable<number> => {
  let attempt = 0;

  return (attempts: Observable<Error>) => attempts
    .pipe(
      mergeMap((error: Error) => {
        if (match) {
          if (!match(error)) {
            // Apply retry strategy only if we match condition at the input function
            return throwError(error);
          }
        }

        attempt += 1;

        if (attempt > delays.length) {
          // If maximum number of attempts have been met - throw error
          // (we will go out from retry block)
          return throwError(error);
        }

        if (attemptNotifier) {
          attemptNotifier(
            error,
            attempt,
            delays[attempt - 1],
          );
        }

        // Run timer based on strategy
        return timer(delays[attempt - 1]);
      }),
    );
};

