import { HttpBackend, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { FALLBACK_APP_LANGUAGE } from '@Libs/model';
import { NonBlockingLoaderService } from '@WebUi/app/services/non-blocking-loader.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateDefaultParser, TranslateLoader, TranslateModuleConfig, TranslateParser } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import packageInfo from 'package.json';
import { Injectable } from '@angular/core';
import { clone } from '@WebUi/helpers/clone';
import { RollbarService } from '@WebUi/app/configs/rollbar.config';
import Rollbar from 'rollbar';
import { environment } from '@WebUi/env';

export class TranslateHttpLoader implements TranslateLoader {

  constructor(
    private httpBackend: HttpBackend,
    private nonBlockingLoaderService: NonBlockingLoaderService,
    public prefix: string,
    public suffix: string,
  ) { }

  getTranslation(lang: string): Observable<unknown> {
    this.nonBlockingLoaderService.start();

    return this.httpBackend.handle(new HttpRequest(
      'GET',
      `${this.prefix}${lang}${this.suffix}`,
      {
        responseType: 'json'
      },
    ))
      .pipe(
        filter((httpEvent: HttpEvent<unknown>): httpEvent is HttpResponse<unknown> => httpEvent.type === HttpEventType.Response),
        map((httpResponse: HttpResponse<unknown>) => httpResponse.body),
        finalize(() => {
          this.nonBlockingLoaderService.stop();
        }),
      );
  }

}

@Injectable()
export class ExtendedTranslateParser extends TranslateDefaultParser {

  constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  override interpolate(expr: string | Object | Function, params?: unknown): any {
    if (typeof expr === 'string') {
      return this.interpolateStr(expr, params);
    } else if (typeof expr === 'function') {
      return this.interpolateFn(expr, params);
    } else if (typeof expr === 'object') {
      const cloneExpr = clone(expr);

      this.interpolateObj(cloneExpr, params);

      return cloneExpr;
    } else {
      return expr as string;
    }
  }

  private interpolateStr(expr: string, params?: unknown): string {
    if (!params) {
      return expr;
    }

    return expr.replace(this.templateMatcher, (substring: string, b: string) => {
      const r = this.getValue(params, b);

      return r !== null && r !== undefined ? r : substring;
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private interpolateFn(fn: Function, params?: unknown): string {
    return fn(params);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private interpolateObj(expr: any, params?: unknown): void {
    if (typeof expr !== 'object') {
      return;
    }

    for (const prop in expr) {
      if (typeof expr[prop] === 'object') {
        this.interpolateObj(expr[prop], params);
      } else {
        expr[prop] = this.interpolateStr(expr[prop], params);
      }
    }
  }

}

@Injectable()
export class ExtendedMissingTranslationHandler implements MissingTranslationHandler {

  private readonly handledKeys: Set<string> = new Set<string>();

  constructor(private rollbarService: Rollbar) { }

  handle(params: MissingTranslationHandlerParams): string {
    if (environment.name !== 'local' && environment.name !== 'unittests') {
      if (!this.handledKeys.has(params.key)) {
        this.rollbarService.error('Missing translation', params.key, params.interpolateParams);

        this.handledKeys.add(params.key);
      }
    }

    return params.key;
  }

}

export function translateLoaderFactory(httpBackend: HttpBackend, nonBlockingLoaderService: NonBlockingLoaderService): TranslateLoader {
  return new TranslateHttpLoader(httpBackend, nonBlockingLoaderService, './assets/i18n/', '.json?v=' + packageInfo.version);
}

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: translateLoaderFactory,
    deps: [
      HttpBackend,
      NonBlockingLoaderService,
    ],
  },
  parser: {
    provide: TranslateParser,
    useClass: ExtendedTranslateParser,
  },
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: ExtendedMissingTranslationHandler,
    deps: [
      RollbarService,
    ],
  },
  defaultLanguage: FALLBACK_APP_LANGUAGE,
};


export const TRANSLATE_CHILD_MODULE_CONFIG: TranslateModuleConfig = {
  ...TRANSLATE_MODULE_CONFIG,
  extend: true,
};
