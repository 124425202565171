export interface SignalRMessage {
  id: string,
  subject: string,
  topic: string,
  eventType: SignalREventType.WEBUI,
  eventTime: string,
  data: {
    messageCode: SignalRMessageCode,
    notificationType: SignalRNotificationType,
    notificationContent: string,
    recipientType: SignalRRecipientType,
    recipientId: string, // companyId | userId | empty (all subscribers)
  },
}

export interface SuSignalRMessage {
  id: string,
  subject: string,
  topic: string,
  eventType: SignalREventType.SU,
  eventTime: string,
  data: {
    messageCode: SuSignalRMessageCode,
    notificationType: SignalRNotificationType,
    notificationContent: string,
    recipientType: SignalRRecipientType,
    recipientId: string, // companyId | userId | empty (all subscribers)
  },
}

export enum SignalREventType {
  WEBUI = 'UserNotification',
  SU = 'SuperUserNotification',
}

export enum SignalRMessageCode {
  COMPANY_UPDATED = 'Company.Updated',
  COMPANY_DELETED = 'Company.Deleted',
  COMPANY_ACCESS_REVOKED = 'Company.Access.Revoked',
  COMPANY_CONNECTION_CREATED = 'Company.Connection.Created',
  COMPANY_CONNECTION_UPDATED = 'Company.Connection.Updated',
  COMPANY_CONNECTION_DELETED = 'Company.Connection.Deleted',
  REPORT_INVOICE_CREATED = 'Report.Invoice.Created',
  REPORT_INVOICE_UPDATED = 'Report.Invoice.Updated',
  REPORT_PAYOUT_TRANSACTION = 'Report.Payout.Transaction',
  REPORT_PRODUCT_SNAPSHOT = 'Report.Product.Snapshot',
  REPORT_ORDER_EVENT = 'Report.Order.Event',
  ORDER_DISTRIBUTION_CONFIGURATION_UPDATED = 'OrderDistribution.Configuration.Updated',
}

export enum SuSignalRMessageCode {
  REPORT_BILLING_TRANSACTION = 'Report.Billing.Transaction',
}

export enum SignalRNotificationType {
  INFORMATION = 'information',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum SignalRRecipientType {
  USER = 'user',
  GROUP = 'group',
  ALL = 'all',
}

