import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import {
  CompanyConnectionCreatedFeedback,
  CompleteCompanyConnection,
  CreateCompanyConnection,
  DeleteCompany,
  DeleteCompanyConnection,
  GetExternalSystemTypeKinds,
  GetUser,
  GetUserCompanies,
  CreateCompany,
  CreateCompanyFeedback,
  RevokeCompanyAccess,
  SetActiveCompany,
  StoreCompanyAccountingSystemConnection,
  StoreTransactionsWidgetSettings,
  ToggleDashboardDemoData,
  ToggleDashboardSidebar,
  UpdateCompany,
  UpdateCompanyConnection,
} from '@WebUi/dashboard/store/dashboard.actions';
import { DashboardStateModel, DASHBOARD_STATE_DEFAULTS } from '@WebUi/dashboard/models/dashboard-state.model';
import {
  CompleteCompanyConnectionResponse,
  CompleteCompanyConnectionVariables,
  COMPLETE_COMPANY_CONNECTION,
  CreateCompanyResponse,
  CreateCompanyVariables,
  CREATE_COMPANY,
  DeleteCompanyConnectionResponse,
  DeleteCompanyConnectionVariables,
  DeleteCompanyResponse,
  DeleteCompanyVariables,
  DELETE_COMPANY,
  DELETE_COMPANY_CONNECTION,
  GetUserCompaniesResponse,
  GetUserResponse,
  GET_USER,
  GET_USER_COMPANIES,
  RevokeCompanyAccessResponse,
  RevokeCompanyAccessVariables,
  REVOKE_COMPANY_ACCESS,
  UpdateCompanyConnectionResponse,
  UpdateCompanyConnectionVariables,
  UpdateCompanyResponse,
  UpdateCompanyVariables,
  UPDATE_COMPANY,
  UPDATE_COMPANY_CONNECTION,
  ID,
  ExternalSystemTypeKind,
  ExternalSystemType,
  isExternalSystemTypeActive,
  isAccountingSystemConnectionPredicate,
  CreateCompanyConnectionVariables,
  CreateCompanyConnectionResponse,
  CREATE_COMPANY_CONNECTION,
} from '@Libs/model';
import { clone } from '@WebUi/helpers/clone';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { append, patch, updateItem } from '@ngxs/store/operators';
import {
  CompanyConnection,
  CompanyConnectionState,
  CompanyUser,
  CompanyUserRole,
  User,
  UserCompany,
} from '@Libs/model';
import { DashboardSettings, DashboardSidebarToggleState } from '@WebUi/dashboard/models/dashboard.model';
import {
  CompanyConnectionCreatedSignal,
  CompanyConnectionDeletedSignal,
  CompanyConnectionUpdatedSignal,
} from '@WebUi/notifications/store/notifications.actions';
import { CompanyManagerApiService } from '@WebUi/dashboard/services/company-manager-api.service';

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: DASHBOARD_STATE_DEFAULTS,
})
@Injectable({
  providedIn: 'root',
})
export class DashboardState {

  @Selector([DashboardState])
  static dashboardSettings(state: DashboardStateModel): DashboardSettings {
    return state.dashboardSettings;
  }

  @Selector([DashboardState])
  static externalSystemTypeKinds(state: DashboardStateModel): ExternalSystemTypeKind[] | null {
    return state.externalSystemTypeKinds?.map((externalSystemTypeKind: ExternalSystemTypeKind) => {
      return {
        ...externalSystemTypeKind,
        externalSystemTypes: externalSystemTypeKind.externalSystemTypes.filter(isExternalSystemTypeActive),
      }
    }) ?? null;
  }

  @Selector([DashboardState.externalSystemTypeKinds])
  static externalSystemTypes(externalSystemTypeKinds: ExternalSystemTypeKind[] | null): ExternalSystemType[] | null {
    if (!externalSystemTypeKinds) {
      return null;
    }

    return externalSystemTypeKinds.reduce((acc: ExternalSystemType[], externalSystemTypeKind: ExternalSystemTypeKind) => {
      return acc.concat(externalSystemTypeKind.externalSystemTypes);
    }, []);
  }

  @Selector([DashboardState])
  static user(state: DashboardStateModel): User {
    return state.user;
  }

  @Selector([DashboardState.user])
  static userInitials(user: User): string {
    const fullName: string = user.fullName
      .split(' ')
      .reduce((accumulator: string, chunk: string) => accumulator.concat(chunk ? chunk[0].toUpperCase() : ''), '');

    return fullName ? fullName : '??';
  }

  @Selector([DashboardState.user])
  static userCompanies(user: User): UserCompany[] {
    return user.companies;
  }

  @Selector([DashboardState.userCompanies])
  static hasUserCompanies(companies: UserCompany[]): boolean {
    return companies.length > 0;
  }

  @Selector([DashboardState])
  static activeUserCompanyId(state: DashboardStateModel): ID | null {
    return state.activeUserCompanyId;
  }

  @Selector([DashboardState.userCompanies, DashboardState.activeUserCompanyId])
  static activeUserCompany(userCompanies: UserCompany[], activeUserCompanyId: ID | null): UserCompany | null {
    if (!activeUserCompanyId) {
      return null;
    }

    return userCompanies.find((company: UserCompany) => company.id === activeUserCompanyId) ?? null;
  }

  @Selector([DashboardState.activeUserCompany])
  static activeUserCompanyConnections(activeUserCompany: UserCompany | null): CompanyConnection[] | null {
    return activeUserCompany?.connections ?? null;
  }

  @Selector([DashboardState.activeUserCompanyConnections])
  static activeUserCompanyHasConnections(activeUserCompanyConnections: CompanyConnection[] | null): boolean | null {
    return activeUserCompanyConnections ? activeUserCompanyConnections.length > 0 : null;
  }

  @Selector([DashboardState.activeUserCompanyConnections])
  static activeUserCompanyNotBrokenConnections(activeUserCompanyConnections: CompanyConnection[] | null): CompanyConnection[] | null {
    return activeUserCompanyConnections?.filter((activeUserCompanyConnection: CompanyConnection) => activeUserCompanyConnection.state !== CompanyConnectionState.BROKEN) ?? null;
  }

  @Selector([DashboardState.activeUserCompanyNotBrokenConnections])
  static activeUserCompanyHasNotBrokenConnections(activeUserCompanyNotBrokenConnections: CompanyConnection[] | null): boolean | null {
    return activeUserCompanyNotBrokenConnections ? activeUserCompanyNotBrokenConnections.length > 0 : null;
  }

  @Selector([DashboardState.activeUserCompanyConnections])
  static activeUserCompanyBrokenConnections(activeUserCompanyConnections: CompanyConnection[] | null): CompanyConnection[] | null {
    return activeUserCompanyConnections?.filter((activeUserCompanyConnection: CompanyConnection) => activeUserCompanyConnection.state === CompanyConnectionState.BROKEN) ?? null;
  }

  @Selector([DashboardState.activeUserCompanyBrokenConnections])
  static activeUserCompanyHasBrokenConnections(activeUserCompanyBrokenConnections: CompanyConnection[] | null): boolean | null {
    return activeUserCompanyBrokenConnections ? activeUserCompanyBrokenConnections.length > 0 : null;
  }

  @Selector([
    DashboardState.activeUserCompanyConnections,
    DashboardState.externalSystemTypes,
  ])
  static activeUserCompanyAccountingSystemConnection(
    connections: CompanyConnection[],
    externalSystemTypes: ExternalSystemType[] | null,
  ): CompanyConnection | null {
    if (!externalSystemTypes) {
      return null;
    }

    return connections.find(isAccountingSystemConnectionPredicate(externalSystemTypes)) ?? null;
  }

  @Selector([DashboardState.user, DashboardState.activeUserCompany])
  static isUserOwnerOfActiveCompany(user: User, activeUserCompany: UserCompany | null): boolean | null {
    if (!activeUserCompany) {
      return false;
    }

    const companyUser: CompanyUser | undefined = activeUserCompany.users.find((companyUser: CompanyUser) => companyUser.userId === user.id);

    if (!companyUser) {
      return false;
    }

    return companyUser.role === CompanyUserRole.OWNER;
  }

  constructor(
    private apollo: Apollo,
    private companyManagerApiService: CompanyManagerApiService,
  ) { }

  @Action(ToggleDashboardSidebar)
  toggleDashboardSidebar(ctx: StateContext<DashboardStateModel>): void {
    const sidebarToggleState: DashboardSidebarToggleState = ctx.getState().dashboardSettings.sidebarToggleState;

    ctx.setState(
      patch({
        dashboardSettings: patch({
          sidebarToggleState: sidebarToggleState === DashboardSidebarToggleState.EXPANDED ? DashboardSidebarToggleState.COLLAPSED : DashboardSidebarToggleState.EXPANDED,
        }),
      }),
    );
  }

  @Action(ToggleDashboardDemoData)
  toggleDashboardDemoData(ctx: StateContext<DashboardStateModel>): void {
    const showDemoData: boolean = ctx.getState().dashboardSettings.showDemoData;

    ctx.setState(
      patch({
        dashboardSettings: patch({
          showDemoData: !showDemoData,
        }),
      }),
    );
  }

  @Action(StoreTransactionsWidgetSettings)
  storeTransactionsWidgetSettings(ctx: StateContext<DashboardStateModel>, payload: StoreTransactionsWidgetSettings): void {
    ctx.setState(
      patch({
        dashboardSettings: patch({
          widgets: patch({
            transactions: payload.transactionsWidgetSettings,
          }),
        }),
      }),
    );
  }

  @Action(GetExternalSystemTypeKinds)
  getExternalSystemTypeKinds(ctx: StateContext<DashboardStateModel>): Observable<ExternalSystemTypeKind[] | null> {
    return this.companyManagerApiService.fetchExternalSystemTypeKind()
      .pipe(
        tap((result: ExternalSystemTypeKind[] | null) => {
          result?.sort((a: ExternalSystemTypeKind, b: ExternalSystemTypeKind) => a.displayOrder - b.displayOrder);

          result?.forEach((externalSystemTypeKind: ExternalSystemTypeKind) => {
            externalSystemTypeKind.externalSystemTypes.sort((a: ExternalSystemType, b: ExternalSystemType) => a.displayOrder - b.displayOrder);
          });

          ctx.patchState({
            externalSystemTypeKinds: result,
          });
        }),
      );
  }

  @Action(GetUser)
  getUser(ctx: StateContext<DashboardStateModel>): Observable<ApolloQueryResult<GetUserResponse>> {
    return this.apollo.use('companyManager').query<GetUserResponse>({
      query: GET_USER,
    })
      .pipe(
        filter((result: ApolloQueryResult<GetUserResponse>) => !!result && !result.loading && !result.partial && !result.error && !result.errors && !!result.data),
        tap((result: ApolloQueryResult<GetUserResponse>) => {
          ctx.patchState({
            user: result.data.user,
          });

          const activeUserCompanyId: ID | null = ctx.getState().activeUserCompanyId;
          const isActiveCompanyExists: boolean = ctx.getState().user.companies.some((company: UserCompany) => company.id === activeUserCompanyId);

          if (!isActiveCompanyExists) {
            if (result.data.user.companies[0]) {
              ctx.patchState({
                activeUserCompanyId: result.data.user.companies[0].id,
              });
            } else {
              ctx.patchState({
                activeUserCompanyId: DASHBOARD_STATE_DEFAULTS.activeUserCompanyId,
              });
            }
          }
        }),
      );
  }

  @Action(GetUserCompanies)
  getUserCompanies(ctx: StateContext<DashboardStateModel>): Observable<ApolloQueryResult<GetUserCompaniesResponse>> {
    return this.apollo.use('companyManager').query<GetUserCompaniesResponse>({
      query: GET_USER_COMPANIES,
    })
      .pipe(
        filter((result: ApolloQueryResult<GetUserCompaniesResponse>) => !!result && !result.loading && !result.partial && !result.error && !result.errors && !!result.data),
        tap((result: ApolloQueryResult<GetUserCompaniesResponse>) => {
          ctx.setState(
            patch({
              user: patch({
                companies: result.data.user.companies,
              }),
            }),
          );

          const activeUserCompanyId: ID | null = ctx.getState().activeUserCompanyId;
          const isActiveCompanyExists: boolean = ctx.getState().user.companies.some((company: UserCompany) => company.id === activeUserCompanyId);

          if (!isActiveCompanyExists) {
            if (result.data.user.companies[0]) {
              ctx.patchState({
                activeUserCompanyId: result.data.user.companies[0].id,
              });
            } else {
              ctx.patchState({
                activeUserCompanyId: DASHBOARD_STATE_DEFAULTS.activeUserCompanyId,
              });
            }
          }
        }),
      );
  }

  @Action(SetActiveCompany)
  setActiveCompany(ctx: StateContext<DashboardStateModel>, payload: SetActiveCompany): void {
    ctx.patchState({
      activeUserCompanyId: payload.companyId,
    });
  }

  @Action(DeleteCompany)
  deleteCompany(ctx: StateContext<DashboardStateModel>, payload: DeleteCompany): Observable<FetchResult<DeleteCompanyResponse>> {
    return this.apollo.use('companyManager').mutate<DeleteCompanyResponse, DeleteCompanyVariables>({
      mutation: DELETE_COMPANY,
      variables: {
        companyId: payload.companyId,
      },
    })
      .pipe(
        tap((result: FetchResult<DeleteCompanyResponse>) => {
          if (!result.data) {
            return;
          }

          if (!result.data.deleteCompany) {
            // Company has not been deleted on the server
            return;
          }

          const newState: DashboardStateModel = clone(ctx.getState());

          newState.user.companies = newState.user.companies.filter((userCompany: UserCompany) => userCompany.id !== payload.companyId);

          ctx.setState(newState);

          const newActiveCompany: UserCompany | undefined = newState.user.companies.find((userCompany: UserCompany) => userCompany.id !== payload.companyId);

          if (newActiveCompany) {
            ctx.patchState({
              activeUserCompanyId: newActiveCompany.id,
            });
          } else {
            ctx.patchState({
              activeUserCompanyId: DASHBOARD_STATE_DEFAULTS.activeUserCompanyId,
            });
          }
        }),
      );
  }

  @Action(CreateCompany)
  createCompany(ctx: StateContext<DashboardStateModel>, payload: CreateCompany): Observable<void> {
    return this.apollo.use('companyManager').mutate<CreateCompanyResponse, CreateCompanyVariables>({
      mutation: CREATE_COMPANY,
      variables: {
        company: {
          companyNumber: payload.company.companyNumber,
          name: payload.company.name,
          billingAddress: {
            address1: payload.company.billingAddress.address1,
            postalCode: payload.company.billingAddress.postalCode,
            postalArea: payload.company.billingAddress.postalArea,
            countryCode: payload.company.billingAddress.countryCode,
          },
          contactPerson: {
            email: payload.company.contactPerson.email,
            name: payload.company.contactPerson.name,
            phone: payload.company.contactPerson.phone,
          },
          invoiceRecipientEmail: payload.company.invoiceRecipientEmail,
        },
      },
    })
      .pipe(
        switchMap((result: FetchResult<CreateCompanyResponse>) => {
          if (!result.data) {
            return throwError('CreateCompanyError');
          }

          const newState: DashboardStateModel = clone(ctx.getState());

          newState.user.companies = newState.user.companies.filter((userCompany: UserCompany) => userCompany.id !== result.data!.createCompany.id);
          newState.user.companies.push(result.data.createCompany);

          ctx.setState(newState);

          return ctx.dispatch([
            new SetActiveCompany(result.data.createCompany.id),
            new CreateCompanyFeedback(result.data.createCompany),
          ]);
        }),
      );
  }

  @Action(UpdateCompany)
  updateCompany(ctx: StateContext<DashboardStateModel>, payload: UpdateCompany): Observable<FetchResult<UpdateCompanyResponse>> {
    return this.apollo.use('companyManager').mutate<UpdateCompanyResponse, UpdateCompanyVariables>({
      mutation: UPDATE_COMPANY,
      variables: {
        companyId: payload.company.id,
        companyName: payload.company.name,
        companyNumber: payload.company.companyNumber,
        billingAddress: {
          address1: payload.company.billingAddress.address1,
          countryCode: payload.company.billingAddress.countryCode,
          postalArea: payload.company.billingAddress.postalArea,
          postalCode: payload.company.billingAddress.postalCode,
        },
        contactPerson: {
          email: payload.company.contactPerson.email,
          name: payload.company.contactPerson.name,
          phone: payload.company.contactPerson.phone,
        },
        invoiceRecipientEmail: payload.company.invoiceRecipientEmail,
      },
    })
      .pipe(
        tap((result: FetchResult<UpdateCompanyResponse>) => {
          if (!result.data) {
            return;
          }

          const newState: DashboardStateModel = clone(ctx.getState());
          const company: UserCompany | undefined = newState.user.companies.find((company: UserCompany) => company.id === payload.company.id);

          if (!company) {
            return;
          }

          company.name = result.data.updateCompany.name;
          company.companyNumber = result.data.updateCompany.companyNumber;
          company.billingAddress = result.data.updateCompany.billingAddress;
          company.contactPerson = result.data.updateCompany.contactPerson;
          company.invoiceRecipientEmail = result.data.updateCompany.invoiceRecipientEmail;

          ctx.setState(newState);
        }),
      );
  }

  @Action(RevokeCompanyAccess)
  revokeCompanyAccess(ctx: StateContext<DashboardStateModel>, payload: RevokeCompanyAccess): Observable<FetchResult<RevokeCompanyAccessResponse>> {
    return this.apollo.use('companyManager').mutate<RevokeCompanyAccessResponse, RevokeCompanyAccessVariables>({
      mutation: REVOKE_COMPANY_ACCESS,
      variables: {
        companyId: payload.companyId,
        userId: payload.userId,
      },
    })
      .pipe(
        tap((result: FetchResult<RevokeCompanyAccessResponse>) => {
          if (!result.data) {
            return;
          }

          if (!result.data.revokeCompanyAccess) {
            // Company access has not been revoked on the server
            return;
          }

          const newState: DashboardStateModel = clone(ctx.getState());

          // TODO - why do we have this condition?
          if (newState.user.id === payload.userId) {
            // User can not revoke own acces
            return;
          }

          const userCompany: UserCompany | undefined = newState.user.companies.find((userCompany: UserCompany) => userCompany.id === payload.companyId);

          if (userCompany) {
            userCompany.users = userCompany.users.filter((companyUser: CompanyUser) => !(companyUser.companyId === payload.companyId && companyUser.userId === payload.userId));
          }

          ctx.setState(newState);
        }),
      );
  }

  @Action(CompleteCompanyConnection)
  completeCompanyConnection(ctx: StateContext<DashboardStateModel>, payload: CompleteCompanyConnection): Observable<void> {
    return this.apollo.use('companyManager').mutate<CompleteCompanyConnectionResponse, CompleteCompanyConnectionVariables>({
      mutation: COMPLETE_COMPANY_CONNECTION,
      variables: {
        companyId: payload.companyId,
        connectionToken: payload.connectionToken,
        externalSystemId: payload.externalSystemId,
      },
    })
      .pipe(
        switchMap((result: FetchResult<CompleteCompanyConnectionResponse>) => {
          if (!result.data?.completeCompanyConnection) {
            return throwError('CompleteCompanyConnectionError');
          }

          const userCompanies: UserCompany[] = clone(ctx.getState().user.companies);
          const company: UserCompany | undefined = userCompanies.find((company) => company.id === payload.companyId);

          if (!company) {
            return ctx.dispatch([new GetUserCompanies()])
              .pipe(
                switchMap(() => ctx.dispatch([
                  new CompanyConnectionCreatedFeedback(payload.companyId, result.data!.completeCompanyConnection),
                ])),
              );
          }

          const connection: CompanyConnection | undefined = company.connections.find((connection) => connection.id === result.data!.completeCompanyConnection.id);

          if (!connection) {
            company.connections.push(result.data.completeCompanyConnection);

            ctx.setState(
              patch({
                user: patch({
                  companies: userCompanies,
                }),
              }),
            );
          }

          return ctx.dispatch([
            new CompanyConnectionCreatedFeedback(payload.companyId, result.data.completeCompanyConnection),
          ]);
        }),
      );
  }

  @Action(CreateCompanyConnection)
  createCompanyConnection(ctx: StateContext<DashboardStateModel>, payload: CreateCompanyConnection): Observable<void> {
    return this.apollo.use('companyManager').mutate<CreateCompanyConnectionResponse, CreateCompanyConnectionVariables>({
      mutation: CREATE_COMPANY_CONNECTION,
      variables: {
        companyId: payload.companyId,
        externalSystemId: payload.externalSystemId,
        externalSystemTypeId: payload.externalSystemTypeId,
        connectionData: JSON.stringify(payload.connectionData),
        token: payload.token,
      },
    })
      .pipe(
        switchMap((result: FetchResult<CreateCompanyConnectionResponse>) => {
          if (!result.data?.createCompanyConnection) {
            return throwError('CreateCompanyConnectionError');
          }

          const userCompanies: UserCompany[] = clone(ctx.getState().user.companies);
          const company: UserCompany | undefined = userCompanies.find((company) => company.id === payload.companyId);

          if (!company) {
            return ctx.dispatch([new GetUserCompanies()])
              .pipe(
                switchMap(() => ctx.dispatch([
                  new CompanyConnectionCreatedFeedback(payload.companyId, result.data!.createCompanyConnection),
                ])),
              );
          }

          const connection: CompanyConnection | undefined = company.connections.find((connection) => connection.id === result.data!.createCompanyConnection.id);

          if (!connection) {
            company.connections.push(result.data.createCompanyConnection);

            ctx.setState(
              patch({
                user: patch({
                  companies: userCompanies,
                }),
              }),
            );
          }

          return ctx.dispatch([
            new CompanyConnectionCreatedFeedback(payload.companyId, result.data.createCompanyConnection),
          ]);
        }),
      );
  }

  @Action(StoreCompanyAccountingSystemConnection)
  storeCompanyAccountingSystemConnection(
    ctx: StateContext<DashboardStateModel>,
    payload: StoreCompanyAccountingSystemConnection,
  ): void {
    const userCompanies: UserCompany[] = clone(ctx.getState().user.companies);
    const company: UserCompany | undefined = userCompanies.find((company) => company.id === payload.companyId);

    if (!company) {
      return;
    }

    let connection: CompanyConnection | undefined = company.connections.find((connection) => connection.id === payload.connection.id);

    if (connection) {
      connection = { ...payload.connection };
    } else {
      company.connections.push(payload.connection);
    }

    ctx.setState(
      patch({
        user: patch({
          companies: userCompanies,
        }),
      }),
    );
  }

  @Action(UpdateCompanyConnection)
  updateCompanyConnection(
    ctx: StateContext<DashboardStateModel>,
    payload: UpdateCompanyConnection,
  ): Observable<FetchResult<UpdateCompanyConnectionResponse>> {
    return this.apollo.use('companyManager').mutate<UpdateCompanyConnectionResponse, UpdateCompanyConnectionVariables>({
      mutation: UPDATE_COMPANY_CONNECTION,
      variables: {
        companyId: payload.companyId,
        connectionId: payload.connectionId,
        connectionData: JSON.stringify(payload.connectionData),
      },
    })
      .pipe(
        tap((result: FetchResult<UpdateCompanyConnectionResponse>) => {
          if (!result.data?.updateCompanyConnection) {
            return;
          }

          const userCompanies: UserCompany[] = clone(ctx.getState().user.companies);
          const company: UserCompany | undefined = userCompanies.find((company) => company.id === payload.companyId);

          if (!company) {
            return;
          }

          const connection: CompanyConnection | undefined = company.connections.find((connection) => connection.id === result.data!.updateCompanyConnection.id);

          if (!connection) {
            return;
          }

          connection.connectionData = result.data.updateCompanyConnection.connectionData;

          ctx.setState(
            patch({
              user: patch({
                companies: userCompanies,
              }),
            }),
          );
        }),
      );
  }

  @Action(DeleteCompanyConnection)
  deleteCompanyConnection(
    ctx: StateContext<DashboardStateModel>,
    payload: DeleteCompanyConnection,
  ): Observable<FetchResult<DeleteCompanyConnectionResponse>> {
    return this.apollo.use('companyManager').mutate<DeleteCompanyConnectionResponse, DeleteCompanyConnectionVariables>({
      mutation: DELETE_COMPANY_CONNECTION,
      variables: {
        connectionId: payload.companyConnectionId,
      },
    })
      .pipe(
        tap((result: FetchResult<DeleteCompanyConnectionResponse>) => {
          if (!result.data?.deleteCompanyConnection) {
            return;
          }

          const newState: DashboardStateModel = clone(ctx.getState());
          const activeCompany: UserCompany | undefined = newState.user.companies.find((company: UserCompany) => company.id === newState.activeUserCompanyId);

          if (!activeCompany) {
            return;
          }

          const connection: CompanyConnection | undefined = activeCompany.connections.find((connection: CompanyConnection) => connection.id === payload.companyConnectionId);

          if (!connection) {
            return;
          }

          connection.state = CompanyConnectionState.PENDING_DELETE;

          ctx.setState(newState);
        }),
      );
  }

  @Action(CompanyConnectionCreatedSignal)
  companyConnectionCreatedSignal(ctx: StateContext<DashboardStateModel>, payload: CompanyConnectionCreatedSignal): void {
    const userCompany: UserCompany | undefined = ctx.getState().user.companies.find((company: UserCompany) => company.id === payload.companyId);

    if (!userCompany) {
      return;
    }

    const isConnectionExists: boolean = userCompany.connections.some((connection: CompanyConnection) => connection.id === payload.companyConnection.id);

    if (isConnectionExists) {
      return;
    }

    ctx.setState(
      patch({
        user: patch({
          companies: updateItem<UserCompany>(
            (userCompany?: Readonly<UserCompany>) => userCompany?.id === payload.companyId,
            patch({
              connections: append([payload.companyConnection]),
            }),
          ),
        }),
      }),
    );
  }

  @Action(CompanyConnectionUpdatedSignal)
  companyConnectionUpdatedSignal(ctx: StateContext<DashboardStateModel>, payload: CompanyConnectionUpdatedSignal): void {
    ctx.setState(
      patch({
        user: patch({
          companies: updateItem<UserCompany>(
            (userCompany?: Readonly<UserCompany>) => userCompany?.id === payload.companyId,
            patch({
              connections: updateItem(
                (companyConnection?: Readonly<CompanyConnection>) => companyConnection?.id === payload.companyConnection.id,
                payload.companyConnection,
              ),
            }),
          ),
        }),
      }),
    );
  }

  @Action(CompanyConnectionDeletedSignal)
  companyConnectionDeletedSignal(ctx: StateContext<DashboardStateModel>, payload: CompanyConnectionDeletedSignal): void {
    const newState: DashboardStateModel = clone(ctx.getState());
    const company: UserCompany | undefined = newState.user.companies.find((company: UserCompany) => company.id === payload.companyId);

    if (!company) {
      return;
    }

    company.connections = company.connections.filter((connection: CompanyConnection) => connection.id !== payload.companyConnectionId);

    ctx.setState(newState);
  }

}
