import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { BlockingLoaderService } from '@WebUi/app/services/blocking-loader.service';
import { ConnectionsService } from '@WebUi/connections/services/connections.service';
import { CompanyConnectionCreatedFeedback, CreateCompanyConnection } from '@WebUi/dashboard/store/dashboard.actions';
import {
  Agreement,
  CompanyConnection,
  ExternalSystemKind,
  ExternalSystemType,
  ExternalSystemTypeFeature,
  isAccountingSystem,
  PointOfSale,
  UserCompany,
} from '@Libs/model';
import { Store } from '@ngxs/store';
import { iif, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { BillingApiService } from '@WebUi/dashboard/services/billing-api.service';
import { CompaniesService } from '@WebUi/dashboard/services/companies.service';
import { DashboardService } from '@WebUi/dashboard/services/dashboard.service';
import { PermissionsService } from '@WebUi/dashboard/services/permissions.service';
import { SuService } from '@WebUi/su/services/su.service';

@Injectable({
  providedIn: 'root',
})
export class ZettleConnectionHandlerGuard implements CanActivate {

  constructor(
    private connectionsService: ConnectionsService,
    private billingApiService: BillingApiService,
    private permissionsService: PermissionsService,
    private suService: SuService,
    private companiesService: CompaniesService,
    private dashboardService: DashboardService,
    private router: Router,
    private store: Store,
    private blockingLoaderService: BlockingLoaderService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const companyIdParam: string | null = route.queryParamMap.get('companyId');
    const externalSystemIdParam: string | null = route.queryParamMap.get('externalSystemId');
    const externalSystemTypeParam: string | null = route.queryParamMap.get('externalSystemType');
    const scopesParam: string | null = route.queryParamMap.get('scopes');

    if (!companyIdParam || !externalSystemIdParam || !externalSystemTypeParam || !scopesParam) {
      return of(this.router.parseUrl('/dashboard'));
    }

    if (externalSystemTypeParam !== PointOfSale.ZETTLE) {
      return of(this.router.parseUrl('/dashboard'));
    }

    this.blockingLoaderService.start();

    return this.connectionsService.unifiedCompleteConnection(
      () => {
        return this.store.dispatch([new CreateCompanyConnection(
          companyIdParam,
          externalSystemIdParam,
          PointOfSale.ZETTLE,
          {
            connectionSettings: {
              scopes: scopesParam,
            },
          },
        )]);
      },
    )
      .pipe(
        withLatestFrom(
          iif(
            () => this.permissionsService.canUseSuModule(),
            this.suService.getUserCompanyByCompanyId$(companyIdParam),
            this.companiesService.getUserCompanyByCompanyId$(companyIdParam),
          ),
          this.dashboardService.getExternalSystemTypesByKind$(ExternalSystemKind.ACCOUNTING_SYSTEM),
        ),
        take(1),
        switchMap(([newConnectionContext, company, accountingSystems]: [CompanyConnectionCreatedFeedback, UserCompany | undefined, ExternalSystemType[] | null]) => {
          const externalSystemTypes: string[] = [PointOfSale.ZETTLE];

          if (company && accountingSystems) {
            const accountingSystem: CompanyConnection | undefined = company?.connections.find((connection: CompanyConnection) => isAccountingSystem(accountingSystems, connection.externalSystemTypeId))

            if (accountingSystem) {
              externalSystemTypes.push(accountingSystem.externalSystemTypeId);
            }
          }

          return this.billingApiService.fetchAgreementByConstrains(ExternalSystemTypeFeature.PRODUCT_DISTRIBUTION, externalSystemTypes.join(','))
            .pipe(
              switchMap((agreement: Agreement) => {
                return this.billingApiService.postCompanyAgreement(newConnectionContext.companyId, {
                  agreementId: agreement.id,
                  connections: [{
                    systemId: newConnectionContext.companyConnection.externalSystemId,
                    systemType: newConnectionContext.companyConnection.externalSystemTypeId,
                  }],
                });
              }),
              map(() => newConnectionContext),
              catchError(() => of(newConnectionContext)),
            );
        }),
        map((newConnectionContext: CompanyConnectionCreatedFeedback) => this.router.parseUrl(`/connections/${newConnectionContext.companyConnection.id}/configuration`)),
        catchError(() => of(this.router.parseUrl('/dashboard'))),
        finalize(() => {
          this.blockingLoaderService.stop();
        }),
      );
  }

}
