import { CompanyConnection, ID, InvoiceReport, Notification, OrderDistributionConfiguration, OrderEventReport } from '@Libs/model';

export class AddNotification {
  static readonly actionName = 'AddNotification';
  static readonly type = '[Notifications] Add notification';

  constructor(public notification: Notification) { }
}

export class DeleteNotification {
  static readonly actionName = 'DeleteNotification';
  static readonly type = '[Notifications] Delete notification';

  constructor(public notificationId: string) { }
}

export class MarkNotificationAsRead {
  static readonly actionName = 'MarkNotificationAsRead';
  static readonly type = '[Notifications] Mark notification as read';

  constructor(public notificationId: string) { }
}

export class MarkAllNotificationsAsRead {
  static readonly actionName = 'MarkAllNotificationsAsRead';
  static readonly type = '[Notifications] Mark all notifications as read';
}

export class CompanyDeletedSignal {
  static readonly actionName = 'CompanyDeletedSignal';
  static readonly type = '[Notifications] Company deleted signal';

  constructor(public companyId: ID) { }
}

export class CompanyConnectionCreatedSignal {
  static readonly actionName = 'CompanyConnectionCreatedSignal';
  static readonly type = '[Notifications] Company connection created signal';

  constructor(public companyId: ID, public companyConnection: CompanyConnection) { }
}

export class CompanyConnectionUpdatedSignal {
  static readonly actionName = 'CompanyConnectionUpdatedSignal';
  static readonly type = '[Notifications] Company connection updated signal';

  constructor(public companyId: ID, public companyConnection: CompanyConnection) { }
}

export class CompanyConnectionDeletedSignal {
  static readonly actionName = 'CompanyConnectionDeletedSignal';
  static readonly type = '[Notifications] Company connection deleted signal';

  constructor(public companyId: ID, public companyConnectionId: ID) { }
}

export class InvoiceReportCreatedSignal {
  static readonly actionName = 'InvoiceReportCreatedSignal';
  static readonly type = '[Notifications] Invoice report created signal';

  constructor(public invoiceReport: InvoiceReport) { }
}

export class InvoiceReportUpdatedSignal {
  static readonly actionName = 'InvoiceReportUpdatedSignal';
  static readonly type = '[Notifications] Invoice report updated signal';

  constructor(public invoiceReport: InvoiceReport) { }
}

export class OrderEventReportSignal {
  static readonly actionName = 'OrderEventReportSignal';
  static readonly type = '[Notifications] Order event report signal';

  constructor(public orderEventReport: OrderEventReport) { }
}

export class OrderDistributionConfigurationUpdatedSignal {
  static readonly actionName = 'OrderDistributionConfigurationUpdatedSignal';
  static readonly type = '[Notifications] Order distribution configuration updated signal';

  constructor(public companyId: ID, public orderDistributionConfigurations: OrderDistributionConfiguration[] | null) { }
}
