import { Injectable } from '@angular/core';
import {
  ExternalSystem,
  ExternalSystemKind,
  ExternalSystemType,
  ExternalSystemTypeKind,
} from '@Libs/model';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  @Select(DashboardState.externalSystemTypeKinds) readonly externalSystemTypeKinds$!: Observable<ExternalSystemTypeKind[] | null>;
  @Select(DashboardState.externalSystemTypes) readonly externalSystemTypes$!: Observable<ExternalSystemType[] | null>;

  getExternalSystemTypeKindById$(externalSystemKind: ExternalSystemKind): Observable<ExternalSystemTypeKind | null> {
    // TODO take(1)
    return this.externalSystemTypeKinds$
      .pipe(
        map((externalSystemTypeKinds: ExternalSystemTypeKind[] | null) => {
          if (!externalSystemTypeKinds) {
            return null;
          }

          return externalSystemTypeKinds.find((externalSystemTypeKind: ExternalSystemTypeKind) => externalSystemTypeKind.id === externalSystemKind) ?? null;
        }),
      );
  }

  getExternalSystemTypeById$(externalSystem: ExternalSystem): Observable<ExternalSystemType | null> {
    // TODO take(1)
    return this.externalSystemTypes$
      .pipe(
        map((externalSystemTypes: ExternalSystemType[] | null) => {
          if (!externalSystemTypes) {
            return null;
          }

          return externalSystemTypes.find((externalSystemType: ExternalSystemType) => externalSystemType.id === externalSystem) ?? null;
        }),
      );
  }

  getExternalSystemTypesByKind$(externalSystemKind: ExternalSystemKind): Observable<ExternalSystemType[] | null> {
    return this.getExternalSystemTypesByKinds$([externalSystemKind]);
  }

  getExternalSystemTypesByKinds$(externalSystemKinds: ExternalSystemKind[]): Observable<ExternalSystemType[] | null> {
    return this.externalSystemTypes$
      .pipe(
        map((externalSystemTypes: ExternalSystemType[] | null) => {
          if (!externalSystemTypes) {
            return null;
          }

          return externalSystemTypes.filter((externalSystemType: ExternalSystemType) => externalSystemKinds.includes(externalSystemType.typeKindId));
        }),
      );
  }

  trackByExternalSystemTypeId(index: number, externalSystemType: ExternalSystemType): ExternalSystem {
    return externalSystemType.id;
  }

  trackByExternalSystemTypeKindId(index: number, externalSystemTypeKind: ExternalSystemTypeKind): ExternalSystemKind {
    return externalSystemTypeKind.id;
  }

}
