import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
  ClearhausSettings,
  CompanyConnection,
  ExternalSystem,
  ExternalSystemKind,
  ExternalSystemType,
  ExternalSystemTypeKind,
  ID,
  KlarnaSettings,
  NetsSettings,
  PaymentProvider,
  PaymentVendorSettings,
  PaypalSettings,
  SalesChannel,
  SalesChannelSettings,
  StripeSettings,
  SveaSettings,
  UserCompany,
} from '@Libs/model';
import { DashboardService } from '@WebUi/dashboard/services/dashboard.service';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { ConnectionsState } from '@WebUi/connections/store/connections.state';
import { CompanyConnectionCreatedFeedback, SetActiveCompany } from '@WebUi/dashboard/store/dashboard.actions';
import { ActiveCompanyService } from '@WebUi/dashboard/services/active-company.service';
import { ExternalSystemToConnect, ExternalSystemToConnectIntention } from '@WebUi/connections/models/connections.model';
import { CompaniesService } from '@WebUi/dashboard/services/companies.service';
import { generateConnectionName } from '@WebUi/helpers/autogenerate-connection-name';

@Injectable({
  providedIn: 'root',
})
export class ConnectionsService {

  @Select(ConnectionsState.externalSystemToConnect) readonly externalSystemToConnect$!: Observable<ExternalSystemToConnect | null>;

  @Select(ConnectionsState.externalSystemToConnectIntention) readonly externalSystemToConnectIntention$!: Observable<ExternalSystemToConnectIntention | null>;

  constructor(
    private dashboardService: DashboardService,
    private activeCompanyService: ActiveCompanyService,
    private companiesService: CompaniesService,
    private store: Store,
    private actions$: Actions,
  ) { }

  trackByCompanyConnectionId(index: number, companyConnection: CompanyConnection): ID {
    return companyConnection.id;
  }

  getActiveCompanyConnectionById$(companyConnectionId: ID): Observable<CompanyConnection | null> {
    return this.activeCompanyService.activeCompanyConnections$
      .pipe(
        map((companyConnections: CompanyConnection[] | null) => companyConnections?.find((companyConnection: CompanyConnection) => companyConnection.id === companyConnectionId) ?? null),
      );
  }

  getActiveCompanyConnectionsByExternalSystemKind$(externalSystemKind: ExternalSystemKind): Observable<CompanyConnection[] | null> {
    return this.dashboardService.getExternalSystemTypeKindById$(externalSystemKind)
      .pipe(
        switchMap((externalSystemTypeKind: ExternalSystemTypeKind | null) => {
          if (!externalSystemTypeKind || !externalSystemTypeKind.externalSystemTypes) {
            return of(null);
          }

          const externalSystems: ExternalSystem[] = externalSystemTypeKind.externalSystemTypes.map((externalSystemType: ExternalSystemType) => externalSystemType.id);

          return this.activeCompanyService.activeCompanyConnections$
            .pipe(
              map((companyConnections: CompanyConnection[] | null) => companyConnections?.filter((companyConnection: CompanyConnection) => externalSystems.includes(companyConnection.externalSystemTypeId)) ?? null),
            );
        }),
      );
  }

  getActiveCompanyConnectionsByExternalSystem$(externalSystem: ExternalSystem): Observable<CompanyConnection[] | null> {
    return this.activeCompanyService.activeCompanyConnections$
      .pipe(
        map((companyConnections: CompanyConnection[] | null) => companyConnections?.filter((companyConnection: CompanyConnection) => companyConnection.externalSystemTypeId === externalSystem) ?? null),
      );
  }

  generateConnectionName$(companyId: ID, externalSystem: ExternalSystem): Observable<string> {
    return this.companiesService.getUserCompanyByCompanyId$(companyId)
      .pipe(
        take(1),
        switchMap((userCompany: UserCompany | undefined) => {
          if (!userCompany) {
            return throwError(() => new Error('Can not find company'));
          }

          const connections: CompanyConnection[] = userCompany.connections.filter((companyConnection: CompanyConnection) => companyConnection.externalSystemTypeId === externalSystem);

          return from(generateConnectionName({
            chosenExternalSystemTypeId: externalSystem,
            companyId: userCompany.id,
            amountOfCompanyConnectionsOfChosenExternalSystemType: connections.length,
            lastConnectionIdOfChosenExternalSystemType: connections[connections.length - 1]?.id ?? '',
          }));
        }),
      );
  }

  unifiedCompleteConnection(
    completeConnectionFn: () => Observable<any>,
  ): Observable<CompanyConnectionCreatedFeedback> {
    return combineLatest([
      this.actions$
        .pipe(
          ofActionDispatched(CompanyConnectionCreatedFeedback),
        ),
      completeConnectionFn(),
    ])
      .pipe(
        take(1),
        switchMap(([context,]: [CompanyConnectionCreatedFeedback, void]) => {
          return this.store.dispatch([new SetActiveCompany(context.companyId)])
            .pipe(
              map(() => context),
            );
        }),
      );
  }

  // Determine if connection has been configured correctly
  isConfiguredCorrectly(companyConnection: CompanyConnection): boolean {
    const salesChannelSettings: SalesChannelSettings | undefined = companyConnection.connectionData?.salesChannelSettings;
    const paymentVendorSettings: PaymentVendorSettings | undefined = companyConnection.connectionData?.paymentVendorSettings;

    switch (companyConnection.externalSystemTypeId) {
      case SalesChannel.GENERIC:
      case SalesChannel.SHOPIFY:
      case SalesChannel.UNIMICRO_NETTBUTIKK:
      case SalesChannel.MOOCOMMERCE:
        return !!salesChannelSettings && !!salesChannelSettings.invoicingStartDateUtc;
      case PaymentProvider.KLARNA:
        return !!paymentVendorSettings && !!paymentVendorSettings.paymentVendorAccount && !!paymentVendorSettings.paymentVendorFeeAccount && !!paymentVendorSettings.paymentVendorInterimAccount && !!paymentVendorSettings.payoutsStartDateUnixTimeSeconds && !!(paymentVendorSettings as KlarnaSettings).username && !!(paymentVendorSettings as KlarnaSettings).password;
      case PaymentProvider.VIPPS:
        return !!paymentVendorSettings && !!paymentVendorSettings.paymentVendorAccount && !!paymentVendorSettings.paymentVendorFeeAccount && !!paymentVendorSettings.paymentVendorInterimAccount && !!paymentVendorSettings.payoutsStartDateUnixTimeSeconds;
      case PaymentProvider.STRIPE:
        return !!paymentVendorSettings && !!paymentVendorSettings.paymentVendorAccount && !!paymentVendorSettings.paymentVendorFeeAccount && !!paymentVendorSettings.paymentVendorInterimAccount && !!paymentVendorSettings.payoutsStartDateUnixTimeSeconds && !!(paymentVendorSettings as StripeSettings).apiSecretKey;
      case PaymentProvider.NETS:
        return !!paymentVendorSettings && !!paymentVendorSettings.paymentVendorAccount && !!paymentVendorSettings.paymentVendorFeeAccount && !!paymentVendorSettings.paymentVendorInterimAccount && !!paymentVendorSettings.payoutsStartDateUnixTimeSeconds && !!(paymentVendorSettings as NetsSettings).privateApiKey;
      case PaymentProvider.PAYPAL:
        return !!paymentVendorSettings && !!paymentVendorSettings.paymentVendorAccount && !!paymentVendorSettings.paymentVendorFeeAccount && !!paymentVendorSettings.paymentVendorInterimAccount && !!paymentVendorSettings.payoutsStartDateUnixTimeSeconds && !!(paymentVendorSettings as PaypalSettings).clientId && !!(paymentVendorSettings as PaypalSettings).clientSecret;
      case PaymentProvider.SVEA:
        return !!paymentVendorSettings && !!paymentVendorSettings.paymentVendorAccount && !!paymentVendorSettings.paymentVendorFeeAccount && !!paymentVendorSettings.paymentVendorInterimAccount && !!paymentVendorSettings.payoutsStartDateUnixTimeSeconds && !!(paymentVendorSettings as SveaSettings).merchantId && !!(paymentVendorSettings as SveaSettings).secretWord;
      case PaymentProvider.CLEARHAUS:
        return !!paymentVendorSettings && !!paymentVendorSettings.paymentVendorAccount && !!paymentVendorSettings.paymentVendorFeeAccount && !!paymentVendorSettings.paymentVendorInterimAccount && !!paymentVendorSettings.payoutsStartDateUnixTimeSeconds && !!(paymentVendorSettings as ClearhausSettings).clientId && !!(paymentVendorSettings as ClearhausSettings).clientSecret;
      default:
        return true;
    }
  }

}
