import { AccountingSystem } from './external-system';
import { ReportListRequestBase, ReportListResponseBase, SortDirectionType } from './report';

export enum InvoiceReportSortKeyType {
  ACCOUNTING_INVOICE_NUMBER = 0,
  CUSTOMER_NAME = 1,
  DATE_UTC = 2,
  STATUS = 3,
}

export const INVOICE_REPORT_SORT_KEY_TYPES: InvoiceReportSortKeyType[] = [
  InvoiceReportSortKeyType.ACCOUNTING_INVOICE_NUMBER,
  InvoiceReportSortKeyType.CUSTOMER_NAME,
  InvoiceReportSortKeyType.DATE_UTC,
  InvoiceReportSortKeyType.STATUS,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isInvoiceReportSortKeyType(sortKeyType: any): sortKeyType is InvoiceReportSortKeyType {
  return INVOICE_REPORT_SORT_KEY_TYPES.includes(sortKeyType);
}

export enum InvoiceReportStatus {
  SUCCESS = 0,
  WARNING = 1,
  FAILURE = 2,
  PENDING = 3,
  PAYMENT_SUCCESS = 4,
  PAYMENT_FAILURE = 5,
  REQUIRES_ATTENTION = 6,
  CORRECTED = 7,
}

export interface InvoiceReportBase {
  accountingSystemId: string | null;
  accountingSystemType: AccountingSystem;
  companyId: number;
  correlationId: number | null;
  currencyCode: string;
  customerName: string;
  dateUtc: string;
  dueDateUtc: string | null;
  id: number;
  originDocumentId: string;
  originSystemId: string;
  originSystemType: string;
  originOrderId: string;
  originOrderNumber: string;
  problemFixInputsStatus: string | null;
  statusMessage: string | null;
  sumInclVat: number;
}

export type InvoiceReportOtherStatus = InvoiceReportBase & {
  accountingInvoiceId: string | null;
  accountingInvoiceNumber: string | null;
  problemId: string | null;
  statusType: InvoiceReportStatus.WARNING | InvoiceReportStatus.PENDING;
};

export type InvoiceReportSuccess = InvoiceReportBase & {
  accountingInvoiceId: string;
  accountingInvoiceNumber: string;
  problemId: null;
  statusType: InvoiceReportStatus.SUCCESS | InvoiceReportStatus.PAYMENT_SUCCESS | InvoiceReportStatus.CORRECTED;
};

export type InvoiceReportRequiresAttention = InvoiceReportBase & {
  accountingInvoiceId: null;
  accountingInvoiceNumber: null;
  problemId: string;
  statusType: InvoiceReportStatus.REQUIRES_ATTENTION;
};

export type InvoiceReportFailed = InvoiceReportRequiresAttention | (InvoiceReportBase & {
  accountingInvoiceId: null;
  accountingInvoiceNumber: null;
  problemId: null;
  statusType: InvoiceReportStatus.FAILURE | InvoiceReportStatus.PAYMENT_FAILURE;
});

export type InvoiceReport = InvoiceReportSuccess | InvoiceReportRequiresAttention | InvoiceReportFailed | InvoiceReportOtherStatus;

export function hasInvoiceReportResolvedProblem(invoiceReport: InvoiceReport, newInvoiceReport: InvoiceReport): boolean {
  if (invoiceReport.id !== newInvoiceReport.id) {
    return false;
  }

  if (invoiceReport.problemId !== null || newInvoiceReport.problemId !== null) {
    return invoiceReport.problemId !== newInvoiceReport.problemId || invoiceReport.statusType !== newInvoiceReport.statusType;
  }

  return invoiceReport.statusType !== newInvoiceReport.statusType;
}

export function isInvoiceReportCorrectable(invoiceReport: InvoiceReport, invoiceReportOrderProblem?: OrderProblem | null): boolean {
  return isInvoiceReportRetryable(invoiceReport, invoiceReportOrderProblem) || isInvoiceReportMatchableToInvoice(invoiceReport, invoiceReportOrderProblem) || isInvoiceReportIgnorable(invoiceReport);
}

export function isInvoiceReportRetryable(invoiceReport: InvoiceReport, invoiceReportOrderProblem?: OrderProblem | null): boolean {
  return isInvoiceReportFailed(invoiceReport) && !!invoiceReportOrderProblem && invoiceReportOrderProblem.isRetryable;
}

export function isInvoiceReportMatchableToInvoice(invoiceReport: InvoiceReport, invoiceReportOrderProblem?: OrderProblem | null): boolean {
  return isInvoiceReportFailed(invoiceReport) && !!invoiceReportOrderProblem && invoiceReport.accountingInvoiceId === null;
}

export function isInvoiceReportIgnorable(invoiceReport: InvoiceReport): boolean {
  return isInvoiceReportFailed(invoiceReport);
}

export function isInvoiceReportRequiresAttention(invoiceReport: InvoiceReport): boolean {
  return invoiceReport.statusType === InvoiceReportStatus.REQUIRES_ATTENTION;
}

export function isInvoiceReportFailed(invoiceReport: InvoiceReport): invoiceReport is InvoiceReportFailed {
  return invoiceReport.statusType === InvoiceReportStatus.REQUIRES_ATTENTION || invoiceReport.statusType === InvoiceReportStatus.FAILURE || invoiceReport.statusType === InvoiceReportStatus.PAYMENT_FAILURE;
}

export function hasInvoiceReportProblem(invoiceReport: InvoiceReport): invoiceReport is InvoiceReportFailed {
  return !!invoiceReport.problemId;
}

export function hasInvoiceReportLink(invoiceReport: InvoiceReport): boolean {
  return invoiceReport.accountingInvoiceNumber !== null;
}

export interface InvoiceReportListRequest extends ReportListRequestBase {
  sortDirection: SortDirectionType | null;
  sortKey: InvoiceReportSortKeyType | null;
  searchKey: string | null;
  status: InvoiceReportStatus[] | null;
  salesChannels: string[] | null;
}

export interface InvoiceReportListResponse extends ReportListResponseBase {
  invoiceReports: InvoiceReport[];
}

export enum OrderProblemType {
  UNMATCHED_CAPTURE_AMOUNT = 0,
  INVOICING_FAILED = 1,
  UNMATCHED_REFUND_AMOUNT = 2,
}

export type OrderProblem = {
  isRetryable: boolean;
  description: string | null;
  problemType: OrderProblemType;
};

export interface UnmatchedRefundAmountOrderProblem extends OrderProblem {
  problemType: OrderProblemType.INVOICING_FAILED,
  transactionAmount: number;
}

export interface UnmatchedCaptureAmountOrderProblem extends OrderProblem {
  problemType: OrderProblemType.UNMATCHED_CAPTURE_AMOUNT,
  transactionAmount: number;
  orderLines: OrderLine[];
}

export function isUnmatchedRefundAmountOrderProblem(problem: OrderProblem): problem is UnmatchedRefundAmountOrderProblem {
  return problem.problemType === OrderProblemType.INVOICING_FAILED;
}

export function isUnmatchedCaptureAmountOrderProblem(problem: OrderProblem): problem is UnmatchedCaptureAmountOrderProblem {
  return problem.problemType === OrderProblemType.UNMATCHED_CAPTURE_AMOUNT && 'orderLines' in problem;
}

export interface OrderLine {
  id: string;
  name: string;
  sku: string | null;
  quantity: number;
  unitPrice: number;
}

export interface InvoiceReportUnmatchedCaptureAmountProblemFixRequest {
  orderLines: OrderLineParams[];
}

export interface OrderLineParams {
  id: string;
  quantity: number;
  sumInclVat: number;
}

export type InvoiceReportProblemFixRequest = InvoiceReportUnmatchedCaptureAmountProblemFixRequest;

export interface InvoiceReportCommonProblemFixResponse {
  errorMessage: string | null;
}

export interface InvoiceReportUnmatchedCaptureAmountProblemFixErrorResponse extends InvoiceReportCommonProblemFixResponse {
  lineErrors: OrderLineError[] | null;
}

export type InvoiceReportProblemFixResponse = InvoiceReportCommonProblemFixResponse | InvoiceReportUnmatchedCaptureAmountProblemFixErrorResponse;

export interface OrderLineError {
  id: string;
  errorMessage: string;
}
